import React from "react";
import PencilIcon from "../images/pencil/index.tsx";
import './style.css';
import { useTranslation } from "react-i18next";



type Props = {
    content: string;
}

const Note = ({content}: Props) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="note">
            <header className="note-header">
                <PencilIcon />
                <h2>{t("Note")}</h2>
            </header>
            <div className="note-content" dangerouslySetInnerHTML={{__html: content}} />
        </div>
    );
}


export default Note;