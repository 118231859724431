import React from "react";

const TickIcon = () => {
    return (
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="var(--text-success)" fill-rule="evenodd" clip-rule="evenodd" d="M15.6649 0.393428C15.9629 0.681057 15.9713 1.15583 15.6837 1.45386L6.1599 11.3222C6.01857 11.4686 5.82379 11.5513 5.62026 11.5513C5.41674 11.5513 5.22196 11.4686 5.08063 11.3222L0.318721 6.38803C0.0310924 6.08999 0.039527 5.61522 0.33756 5.32759C0.635594 5.03996 1.11037 5.0484 1.398 5.34643L5.62026 9.72143L14.6044 0.412268C14.8921 0.114234 15.3668 0.105799 15.6649 0.393428Z" />
        </svg>
    )
};

export default TickIcon;