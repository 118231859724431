import React from "react";

const Flag = () => {
    return (
        <svg width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="var(--icon-primary)" d="M1.62238 26C1.13007 26 0.734732 25.8325 0.436364 25.4975C0.145455 25.1625 0 24.7107 0 24.142V3.69258C0 2.49288 0.298368 1.57753 0.895105 0.946517C1.49184 0.315506 2.35711 0 3.49091 0H12.4979C13.6317 0 14.497 0.315506 15.0937 0.946517C15.6979 1.57753 16 2.49288 16 3.69258V24.142C16 24.7107 15.8508 25.1625 15.5524 25.4975C15.2615 25.8325 14.8662 26 14.3664 26C14.0382 26 13.7399 25.9065 13.4713 25.7196C13.2103 25.5326 12.8448 25.2015 12.3748 24.7263L8.08951 20.2858C8.02984 20.2157 7.96643 20.2157 7.8993 20.2858L3.62517 24.7263C3.15524 25.2015 2.78228 25.5326 2.50629 25.7196C2.23776 25.9065 1.94312 26 1.62238 26Z" />
        </svg>
    );
}

export default Flag;