import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import './style.css';
import { useTranslation } from "react-i18next";

type Props = {
    contentItems: string[]
}

const ContentSlider = ({contentItems}: Props) => {
    const { t, i18n } = useTranslation();
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: "slick-dots",
        adaptiveHeight: true,
        appendDots: dots => (
            <ul className="slick-dots" style={{ display: "flex" }}> {dots} </ul>
        )
    };

    return (
        <div className="content-slider">
            <h2 className="content-slider-header">{t("Technique")}</h2>
            <Slider {...settings} style={{display: "flex"}}>
                {contentItems.map(item => <div className="content-item" dangerouslySetInnerHTML={{__html: item}} />)}
            </Slider>
        </div>
    )
};


export default ContentSlider;