import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import './style.css';

type Props = {
    id: string;
    title: string,
    description: string;
    preview: string;
}

const Exercise = ({id, title, description, preview}: Props) => {
    const titleRef = useRef<HTMLHeadingElement | null>(null);
    const [resultExerciseItemClassName, setResultExerciseItemClassName] = useState<string>("exercise-item");
    const baseHeadlineHeight = 25;

    useEffect(() => {
        if (!titleRef?.current){
            return;
        };

        if (titleRef.current.offsetHeight <= baseHeadlineHeight) {
            setResultExerciseItemClassName(resultExerciseItemClassName + " exercise-item--big-description");
        }
    }, [titleRef]);

    return (
        <Link to={'/exercises/' + id} className={resultExerciseItemClassName}>
            <div className="exercise-item-img">
                <img src={preview} />
            </div>
            <div className="exercise-item-content">
                <h3 ref={titleRef}>{title}</h3>
                <p>{description}</p>
            </div>
        </Link>
    );
};

export default Exercise;