
import React from "react";

const SearchBar = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="var(--icon-tertiary)" fill-rule="evenodd" clip-rule="evenodd" d="M7.59526 3.2509C4.73515 3.2509 2.41709 5.56846 2.41709 8.42667C2.41709 11.2849 4.73515 13.6025 7.59526 13.6025C9.02609 13.6025 10.3201 13.0234 11.2581 12.0852C12.1952 11.1478 12.7734 9.85542 12.7734 8.42667C12.7734 5.56845 10.4554 3.2509 7.59526 3.2509ZM0.917175 8.42667C0.917175 4.7395 3.90734 1.75098 7.59526 1.75098C11.2832 1.75098 14.2733 4.7395 14.2733 8.42667C14.2733 9.99916 13.7287 11.4455 12.819 12.586L17.197 16.9702C17.4897 17.2633 17.4893 17.7381 17.1963 18.0308C16.9032 18.3235 16.4283 18.3231 16.1356 18.0301L11.7584 13.6467C10.6171 14.5573 9.16931 15.1024 7.59526 15.1024C3.90734 15.1024 0.917175 12.1138 0.917175 8.42667Z" />
        </svg>
    );
};

export default SearchBar;