export enum AppRoute {
    Main = '/',
    Category = '/category/:slug',
    Exercise = '/exercises/:id',
    Saved = "/saved",
    About = '/about'
}


export enum APIRoute {
    Payment = '/bot/invoice',
    Categories = '/categories',
    SavedExercises = '/exercises/saved'
}


export const BOT_USERNAME = 'spoty_app_bot';