import React from "react";

import './style.css';
import { Link } from "react-router-dom";

type Props = {
    name: string;
    url: string;
    icon: React.ReactNode;
    count?: number;
}

const Category = ({name, url, icon, count}: Props) => {
    return (
        <Link to={url} className="category">
            <span className="category-icon">
                {icon}
            </span>
            {count && count > 0 && <span className="category-count">{count}</span>}
            <h2>{name}</h2>
        </Link>
    );
}


export default Category;