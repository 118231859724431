import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BackButton } from "@twa-dev/sdk/react";
import browserHistory from "../../browser-history.ts";
import Flag from '../../components/images/flag/index.tsx';
import './style.css';
import Exercise from "../../components/exercise/index.tsx";
import exercisePreview from '../../assets/exercise-preview-small.png';
import { useAppSelector } from "../../hooks/index.ts";
import { ExercisePreview } from "../../types/data.ts";
import { getImageURL } from "../../services/api.ts";
import CategorySection from "../../components/category-section/index.tsx";
import { useTranslation } from "react-i18next";

const SavedPage = () => {
    const { t, i18n } = useTranslation();
    const { savedExercises } = useAppSelector((state) => state);
    const exercisesByCategory: Record<string, ExercisePreview[]> = {};

    savedExercises.forEach((exercise) => {
        if (exercise.category in exercisesByCategory){
            exercisesByCategory[exercise.category].push(exercise);
        } else {
            exercisesByCategory[exercise.category] = [exercise];
        }
    });

    return (
        <div className="saved-page">
            <BackButton onClick={() => browserHistory.back()}/>
            <Container>
                <Row>
                    <Col>
                        <header>
                            <h1>{t('Saved')}</h1>
                            <Flag />
                        </header>
                        {
                            Object.entries(exercisesByCategory).map(
                                ([category, exercises]) => <CategorySection title={category} exercises={exercises} />
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
};



export default SavedPage;