import { createReducer } from "@reduxjs/toolkit";
import { User } from "../types/user.ts";
import { Category, ExercisePreview, Theme } from "../types/data.ts";
import { setCategories, setDataLoadedStatus, setError, setSavedExercises, setTheme } from "./action.ts";

type AppState = {
    name: string;
    user?: User;
    categories?: Category[];
    savedExercises: ExercisePreview[];
    isDataLoaded: boolean;
    theme: Theme;
    hasError: boolean;
};

const initialState: AppState = {
    name: "TestName",
    categories: [],
    savedExercises: [],
    isDataLoaded: false,
    theme: Theme.Dark,
    hasError: false
};


export const reducer = createReducer(initialState, (builder) => {
    builder
    .addCase(setCategories, (state, action) => {
        state.categories = action.payload;
    })
    .addCase(setSavedExercises, (state, action) => {
        state.savedExercises = action.payload || [];
    })
    .addCase(setDataLoadedStatus, (state, action) => {
        state.isDataLoaded = action.payload;
    })
    .addCase(setTheme, (state, action) => {
        state.theme = action.payload;
    })
    .addCase(setError, (state, action) => {
        state.hasError = action.payload;
    })
});

