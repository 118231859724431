import React, { UIEvent, useEffect, useRef } from "react";
import './style.css';

type Props = {
    tags: string[];
    categoryName: string;
}

const TagSection  = ({tags, categoryName}: Props) => {
    const listRef = useRef<HTMLUListElement | null>(null);
    const sectionRef = useRef<HTMLDivElement | null>(null);

    const leftShadowClassName = 'exercise-tags--fade-left';
    const rightShadowClassName = 'exercise-tags--fade-right';

    const setFadeClasses = () => {
        if (!listRef || !listRef.current) {
            return;
        }

        const items = listRef.current.children;

        if (items.length <= 1){
            return;
        }
        
        const firstItem = items[0];
        const lastItem = items[items.length - 1];
        const listPosition = listRef.current.getBoundingClientRect();
        const firstItemPosition = firstItem.getBoundingClientRect();
        const lastItemPosition = lastItem.getBoundingClientRect();
        const firstItemPositionLeftRelativeToParent = firstItemPosition.left - listPosition.left;
        const lastItemPositionRightRelativeToParent = listPosition.right - lastItemPosition.right;

        if (
            firstItemPositionLeftRelativeToParent <= 0 
            && sectionRef?.current) {
            sectionRef.current.classList.add(leftShadowClassName);
        } else if (sectionRef?.current) {
            sectionRef.current.classList.remove(leftShadowClassName);
        }

        if (lastItemPositionRightRelativeToParent >= 0 && sectionRef?.current) {
            sectionRef.current.classList.remove(rightShadowClassName);
        } else if (sectionRef?.current) {
            sectionRef.current.classList.add(rightShadowClassName);
        }
    }
    const handleScrollTagsList = (e: any) => {
        setFadeClasses();
    };

    useEffect(() => {
        setFadeClasses();
    }, []);

    return (
        <section ref={sectionRef} className="exercise-tags">
            <h2>{categoryName}</h2>
            <ul ref={listRef} onScroll={handleScrollTagsList}>
                {tags.map((tag, idx) => <li key={idx}>{tag}</li>)}
            </ul>
        </section>
    )
};

export default TagSection;
