import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import exercisePreview from '../../assets/exercise-preview.png';
import './style.css';
import { BackButton } from '@twa-dev/sdk/react';
import browserHistory from "../../browser-history.ts";
import SaveButton from "../../components/save-button/index.tsx";
import TickIcon from "../../components/images/tick/index.tsx";
import CrossIcon from "../../components/images/cross/index.tsx";
import ContentSlider from "../../components/content-slider/index.tsx";
import Note from "../../components/note/index.tsx";
import { useParams } from "react-router-dom";
import { Exercise } from "../../types/data.ts";
import { api, getImageURL } from "../../services/api.ts";
import { useAppDispatch, useAppSelector } from "../../hooks/index.ts";
import WebApp from "@twa-dev/sdk";
import { setSavedExercises } from "../../store/action.ts";
import { getSavedExercises } from "../../store/api-actions.ts";
import { MainButton } from '@twa-dev/sdk/react';
import { useTranslation } from "react-i18next";
import Button from "../../components/button/index.tsx";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import TagSection from "../../components/tag-section/index.tsx";

type WarningListProps = {
    headline: string;
    content: string;
    isGood: boolean;
}

const WarningList = ({ headline, content, isGood }: WarningListProps) => {
    return (
        <div className={"warning-list " + (isGood ? "good" : "bad")}>
            <header>
                <div className="warning-header-icon">
                    {isGood ? <TickIcon /> : <CrossIcon />}
                </div>
                <h3>{headline}</h3>
            </header>
            <div>
                {content}
            </div>
        </div>
    )
}


const ExercisePage = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [ exercise, setExercise ] = useState<null | Exercise>(null);
    const { savedExercises } = useAppSelector((state) => state);
    const [ isSaved, setIsSaved ] = useState<boolean>(savedExercises.some((exercise) => exercise.id === id));
    const [ isLoadingExercise, setIsLoadingExercise ] = useState<boolean>(true);
    const dispatch = useAppDispatch();

    const renderContentItems = () => {
        return exercise?.content.map((contentItem, idx) => {
            return `
                <div>
                    <img src="${getImageURL(contentItem.image)}"/>
                    <h3>${t("Step", {idx: idx + 1})}<h3>
                    <h4>${contentItem.title}</h4>
                    <div class="exercise-content-item-text">${contentItem.text}</div>
                </div>
            `;
        })
    };

    const renderNoteContent = () => `<div>${exercise?.note}</div>`;

    useEffect(() => {
        if (!id){
            return;
        }

        const fetchExercise = async () => {
            const { data: exerciseData } = await api.get<Exercise>(`/exercises/${id}`);
            setExercise(exerciseData);
        };
        
        setIsLoadingExercise(true);
        fetchExercise().then(_ => setIsLoadingExercise(false));
    }, [id, dispatch]);

    const handleSaveExercise = () => {
        const saveExercise = async () => {
            return await api.post("/exercises/saved", {exercise_id: id});
        };
        
        saveExercise().then((res) => {
            WebApp.HapticFeedback.notificationOccurred("success");
            setIsSaved(true);
            // TODO: попробовать самим вставлять в стейт упражения
            dispatch(getSavedExercises());
        });
    }

    const handleUnsaveExercise = () => {
        const unsaveExercise = async () => {
            return await api.delete(`/exercises/saved/${id}`);
        };

        unsaveExercise().then((res) => {
            WebApp.HapticFeedback.notificationOccurred("success");
            setIsSaved(false);
            dispatch(getSavedExercises());
        });
    };

    const rednerDeleteButton = () => {
        return (
            <div className="delete-button">
                <Button text={t("Delete")} theme="secondary" onClick={handleUnsaveExercise} />
            </div>
        )
    };


    return (
        <div className="exercise-page">
            <BackButton onClick={() => browserHistory.back()}/>
            <Container>
                <Row>
                    <Col>
                        <article className="exercise-article">
                            {
                                isLoadingExercise 
                                ? <Skeleton borderRadius={10} baseColor="var(--surface-secondary)" highlightColor="var(--background-primary)" height={34} style={{marginBottom: 12, maxWidth: 267}}/> 
                                : (exercise && <h1>{exercise.title}</h1>)
                            }
                            {
                                isLoadingExercise 
                                ? <Skeleton baseColor="var(--surface-secondary)" highlightColor="var(--background-primary)" height={180} borderRadius={20}/>
                                : (
                                    <div className="exercise-preview">
                                        {exercise && <img  src={getImageURL(exercise.preview_image)} />}
                                    </div>
                                )
                            }
                            <p className="tags-description">
                                {
                                    isLoadingExercise 
                                    ? <Skeleton 
                                            baseColor="var(--surface-secondary)" 
                                            highlightColor="var(--background-primary)" 
                                            height={14} 
                                            style={{maxWidth: 147, borderRadius: 10, marginTop: 5}}
                                        />
                                    : t("What muscles do we train")
                                }
                            </p>
                            {
                                isLoadingExercise 
                                ? <Skeleton 
                                    baseColor="var(--surface-secondary)" 
                                    highlightColor="var(--background-primary)" 
                                    height={76} 
                                    borderRadius={20}
                                    style={{marginBottom: 24}}
                                />
                                : exercise && <TagSection tags={exercise.tags} categoryName={exercise.category.name} />
                            }
                            <section className="warning-section">
                                <div className="exercise-page-warning-list">
                                    {
                                        isLoadingExercise 
                                        ? <Skeleton 
                                            highlightColor="var(--background-primary)" 
                                            borderRadius={20} 
                                            baseColor="var(--surface-secondary)" 
                                            height={162} 
                                        />
                                        : exercise && <WarningList headline={t("Good technique")} content={exercise.good_technique} isGood={true}/>
                                    }
                                </div>
                                <div className="exercise-page-warning-list">
                                    {
                                        isLoadingExercise
                                        ? <Skeleton 
                                            highlightColor="var(--background-primary)" 
                                            style={{marginBottom: 15}} 
                                            borderRadius={20} 
                                            baseColor="var(--surface-secondary)" 
                                            height={162} 
                                        />
                                        : exercise && <WarningList headline={t("Unsafe technique")} content={exercise.bad_technique} isGood={false} />
                                    }
                                </div>
                            </section>
                            <div className="exercise-content">
                                {
                                    isLoadingExercise 
                                    ? <Skeleton 
                                        style={{marginRight: -24, marginLeft: -24, width: 'auto', display: 'flex'}} 
                                        highlightColor="var(--background-primary)" 
                                        baseColor="var(--surface-secondary)" 
                                        height={416} 
                                    /> 
                                    : <ContentSlider contentItems={renderContentItems() || []} />
                                }
                                <div className="exercise-note">
                                    {
                                        isLoadingExercise 
                                        ? <Skeleton 
                                            style={{marginTop: -15, display: 'flex'}} 
                                            highlightColor="var(--background-primary)" 
                                            baseColor="var(--surface-secondary)" 
                                            height={162} 
                                            borderRadius={20} 
                                        />
                                        : exercise && <Note content={renderNoteContent()} />
                                    }
                                </div>
                            </div>
                            {
                                !isLoadingExercise &&
                                (
                                    isSaved 
                                    ? rednerDeleteButton()
                                    : <MainButton 
                                        text={t("Save")} 
                                        color="#007AFF"
                                        textColor="#FFFFFF"
                                        onClick={handleSaveExercise}
                                    />
                                )
                            }
                        </article>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ExercisePage;