import React from "react";
import './style.css';


type Props = {
    text: string;
    theme?: string;
    onClick: () => void;
}

const Button = ({text, theme = "primary", onClick}: Props) => {
    return (
        <button className={"button " + theme} onClick={onClick}>{text}</button>
    );
};

export default Button;