import Lottie from "lottie-react";
import React from "react";
import LoadingAnimation from '../../assets/loader.json';
import './style.css';

const Loader = () => {
    return (
        <div className="loader">
            <Lottie className="loader-wrapper" animationData={LoadingAnimation} loop={true} />
        </div>
    )
};

export default Loader;