import React from "react";
import {BOT_USERNAME} from '../../const.ts';

const BrowserLaunch = () => {
    return (
        <div>
            You're launching app from browser<br />
            Please, redirect to telegram mini app<br />
            <a href={`https://t.me/${BOT_USERNAME}?startapp`}>Link to bot</a>
        </div>
    );
};


export default BrowserLaunch;