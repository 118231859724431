import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "Saved": "Saved",
            "About app": "About app",
            "Good technique": "Good technique",
            "Unsafe technique": "Unsafe technique",
            "Technique": "Technique",
            "Note": "Note",
            "Step": "Step {{idx}}",
            "Development": "Development",
            "Design": "Design",
            "Texts": "Texts",
            "Version": "Version 1.0",
            "Version Date": "2nd August 2024 year",
            "Delete": "Delete",
            "Save": "Save",
            "There are no such exercises yet": "There are no such exercises yet",
            "Write about not found exercises": "Write to us and we will add, although we may not add. In short, Lera will write the text here",
            "Feedback": "Feedback",
            "What muscles do we train": "What muscles do we train",
            "Reload": "Reload",
            "Something went wrong": "Something went wrong",
            "Error content": "Here Lera writes a text to help users understand what to do to correct the situation",
            "Search": "Search"
        }
    },
    ru: {
        translation: {
            "Saved": "Сохраненное",
            "About app": "О приложении",
            "Good technique": "Хорошая техника",
            "Unsafe technique": "Небезопасная техника",
            "Technique": "Техника",
            "Note": "На заметку",
            "Step": "Шаг {{idx}}",
            "Development": "Разработка",
            "Design": "Дизайн",
            "Texts": "Тексты",
            "Version": "Версия 1.0",
            "Version Date": "от 2 августа 2024 года",
            "Delete": "Удалить",
            "Save": "Сохранить",
            "There are no such exercises yet": "Таких упражнений пока нет",
            "Write about not found exercises": "Напишите нам и мы добавим, хотя может и не добавим. Короче лера текст напишет сюда",
            "Feedback": "Обратная связь",
            "What muscles do we train": "Какие мышцы тренируем",
            "Reload": "Перезагрузить",
            "Something went wrong": "Что-то пошло не так",
            "Error content": "Тут лера пишет текст помогающий юзерам понять что сделать, чтобы исправить ситуацию",
            "Search": "Поиск"
        }
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;