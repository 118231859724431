import React from "react";
import './style.css';
import SearchBar from "../images/search-bar/index.tsx";

type Props = {
    placeholder: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
};

const SearchField = ({placeholder, onChange}: Props) => {
    return (
        <div className="search-field">
            <span className="search-field-icon">
                <SearchBar />
            </span>
            <input type="text" onChange={onChange} placeholder={placeholder} />
        </div>
    )
};


export default SearchField;