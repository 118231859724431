export type Category = {
    id: string;
    name: string;
    icon: string;
    slug: string;
}

export type ExercisePreview = {
    id: string;
    title: string;
    description: string;
    preview: string;
    category: string;
}


export type ExerciseContent = {
    text: string;
    title: string;
    image: string;
};

export type Exercise = {
    id: string;
    title: string;
    tags: string[];
    good_technique: string;
    bad_technique: string;
    content: ExerciseContent[];
    note: string;
    preview_image: string;
    category: Category;
}

export enum Theme {
    Light = 'light',
    Dark = 'dark'
}


export enum PaymentType {
    Subscription = 'subscription',
    Donation = 'donation'
}



export type CreateInvoiceLinkResponse = {
    link: string;
}