import React from "react";
import { ExercisePreview } from "../../types/data";
import { getImageURL } from "../../services/api.ts";
import Exercise from "../exercise/index.tsx";
import './style.css';



type Props = {
    title: string;
    exercises: ExercisePreview[];
}


const CategorySection = ({title, exercises}: Props) => {
    const renderExercisePreview = (exercise: ExercisePreview) => {
        return (
            <div className="category-section-exercise">
                <Exercise 
                    id={exercise.id} 
                    title={exercise.title}
                    description={exercise.description}
                    preview={getImageURL(exercise.preview)} 
                />
            </div>
        )
    }
    return (
        <section className="category-section">
            <h2>{title}</h2>
            {exercises.map((exercise) => renderExercisePreview(exercise))}
        </section>
    );
};

export default CategorySection;