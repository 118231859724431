import React from "react";


const CrossIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="var(--icon-error)" fill-rule="evenodd" clip-rule="evenodd" d="M0.752288 0.700967C1.04322 0.406156 1.51806 0.403009 1.81287 0.693939L7.00038 5.81314L12.1879 0.693939C12.4827 0.403009 12.9575 0.406156 13.2485 0.700967C13.5394 0.995779 13.5363 1.47062 13.2414 1.76155L8.06808 6.86679L13.2414 11.972C13.5363 12.263 13.5394 12.7378 13.2485 13.0326C12.9575 13.3274 12.4827 13.3306 12.1879 13.0396L7.00038 7.92043L1.81287 13.0396C1.51806 13.3306 1.04322 13.3274 0.752288 13.0326C0.461358 12.7378 0.464505 12.263 0.759317 11.972L5.93268 6.86679L0.759317 1.76155C0.464505 1.47062 0.461358 0.995779 0.752288 0.700967Z" />
        </svg>
    );
};

export default CrossIcon;