import { configureStore } from '@reduxjs/toolkit';
import { reducer } from './reducer.ts';
import { api } from '../services/api.ts';
import { AxiosError } from 'axios';
import { setError } from './action.ts';

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
    })
});

// This was made cause of circular import in api.ts (in api import store, in store importing api)
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 500) {
      store.dispatch(setError(true));
    }
    
    throw error;
  }
);