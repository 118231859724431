import './App.css';
import React from 'react';

import browserHistory from './browser-history.ts';
import HistoryRouter from './components/history-router/index.tsx';
import { Routes, Route } from 'react-router-dom';
import { AppRoute } from './const.ts';
import MainPage from './pages/main-page/index.tsx';
import CategoryPage from './pages/category-page/index.tsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import ExercisePage from './pages/exercise-page/index.tsx';
import { useAppSelector } from './hooks/index.ts';
import SavedPage from './pages/saved-page/index.tsx';
import AboutPage from './pages/about-page/index.tsx';
import { Theme } from './types/data.ts';
import Error from './components/error/index.tsx';
import Loader from './components/loader/index.tsx';

const App = () => {
  const { isDataLoaded, theme, hasError } = useAppSelector((state) => state);
  
  return (
    <div className={'app ' + (theme === Theme.Dark ? "dark-theme" : "light-theme")}>
      <div className='app-wrapper'>
        <div className='app-content'>
            {
              !isDataLoaded 
              ? <Loader />
              : (
                hasError 
                ? <Error />
                : 
                <HistoryRouter history={browserHistory}>
                  <Routes>
                    <Route index path={AppRoute.Main} element={<MainPage />} />
                    <Route path={AppRoute.Category} element={<CategoryPage />} />
                    <Route path={AppRoute.Exercise} element={<ExercisePage/>} />
                    <Route path={AppRoute.Saved} element={<SavedPage />} />
                    <Route path={AppRoute.About} element={<AboutPage />} />
                  </Routes>
                </HistoryRouter>
              )
            }
        </div>
      </div>
    </div>
  );
};

export default App;
