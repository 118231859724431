import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import Category from '../../components/category/index.tsx';

import './style.css';
import Flag from '../../components/images/flag/index.tsx';
import { AppRoute } from '../../const.ts';
import { useAppSelector } from '../../hooks/index.ts';
import ArrowRight from '../../components/images/arrow-right/index.tsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const AboutPageLink = () => {
  const { t, i18n } = useTranslation();

  return (
    <Link to={AppRoute.About} className='about-page-link'>
      <span>{t('About app')}</span>
      <ArrowRight />
    </Link>
  )
};

const MainPage = () => {
  const { t, i18n } = useTranslation();
  const { categories, savedExercises } = useAppSelector((state) => state);

  const renderCategory = (id, name, icon, slug) => {
    return (
      <Col>
        <Category key={id} name={name} url={'/category/' + slug} icon={<div dangerouslySetInnerHTML={{__html: icon}} />} />
      </Col>
    )
  }
    return (
      <div className="main-page">
        <Container fluid={true}>
          <Row className='g-3 row-cols-2'>
            {
              categories && categories.map((category) => renderCategory(
                  category.id,
                  category.name, 
                  category.icon,
                  category.slug
                )
              )
            }  
          </Row>
          {
            savedExercises.length > 0 && 
            <Row className='saved-category'>
                <Col lg={12} md={12}>
                  <Category name={t("Saved")} url={AppRoute.Saved} icon={<Flag />} count={savedExercises.length} />
                </Col>
            </Row>
          }
        </Container>
        <Container>
          <Row>
            <Col>
              <AboutPageLink />
            </Col>
          </Row>
        </Container>
      </div>
    )
};

export default MainPage;
