import React from "react";
import AchievementsIcon from "../images/achievements/index.tsx";
import Button from "../button/index.tsx";
import './style.css';
import { useTranslation } from "react-i18next";
import { setError } from "../../store/action.ts";
import { store } from "../../store/index.ts";

const Error = () => {
    const { t, i18n } = useTranslation();
    
    const handleReloadClick = () => {
        store.dispatch(setError(false));
        window.location.reload();
    };

    return (
        <div className="error">
            <AchievementsIcon />
            <h1>{t("Something went wrong")}</h1>
            <p>{t("Error content")}</p>
            <Button text={t("Reload")} onClick={handleReloadClick} />
        </div>
    );
};

export default Error;